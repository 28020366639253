<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>

                <b-form @submit.prevent="search()" >
                  <div class="filter-transaction">
                    <div class="row mb-6">
                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <label>Số điện thoại NGT:</label>
                        <input
                            type="text"
                            placeholder="Nhập số điện thoại..."
                            class="form-control datatable-input"
                            v-model="paramFilter.sender_phone"
                        />
                      </div>

                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <label>Số điện thoại NĐGT:</label>
                        <input
                            type="text"
                            placeholder="Nhập số điện thoại..."
                            class="form-control datatable-input"
                            v-model="paramFilter.receiver_phone"
                        />
                      </div>

                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <label>Trạng thái</label>
                        <select
                            class="form-control datatable-input"
                            data-col-index="7"
                            v-model="paramFilter.status"
                        >
                          <option value="">Tất cả</option>
                          <option value="0">Chờ duyệt</option>
                          <option value="1">Thành công</option>
                        </select>
                      </div>
                    </div>

                    <div class="row mb-6">
                      <b-form-group
                          id="input-group-4"
                          label="Từ ngày:"
                          label-for="dp1"
                          class="col-lg-4"
                      >
                        <date-picker
                            id="dp1"
                            name="dp1"
                            format="YYYY-MM-DD "
                            value-type="format"
                            v-model="paramFilter.from_date"
                            type="date"
                            placeholder="Chọn thời gian"
                        ></date-picker>
                      </b-form-group>
                      <b-form-group
                          id="input-group-4"
                          label="Đến ngày:"
                          label-for="dp1"
                          class="col-lg-4"
                      >
                        <date-picker
                            id="dp1"
                            name="dp1"
                            format="YYYY-MM-DD "
                            value-type="format"
                            v-model="paramFilter.to_date"
                            type="date"
                            placeholder="Chọn thời gian"
                        ></date-picker>
                      </b-form-group>
                      <div class="col-lg-2 mb-lg-0 mb-6">
                        <label>.</label><br />
                        <button
                            class="btn btn-primary btn-primary--icon"
                        >
                          <span>
                            <i class="la la-search"></i>
                              <span>Tìm kiếm</span>
                            </span>
                        </button>
                      </div>
                    </div>

                  </div>
                </b-form>
              </template>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fields"
              >
                <template #cell(stt)="data">
                  <div >
                    {{ data.index + 1}}
                  </div>
                </template>
                <template #cell(user_receiver)="data">
                  <div v-if="data.item.receiver" >
                    <div>
                      <span>User ID:</span> {{data.item.receiver.uuid }}
                    </div>
                    <div v-if="data.item.receiver.name">
                      <span>Họ tên:</span> {{data.item.receiver.name }}
                    </div>
                    <div>
                      <span>SĐT:</span> {{data.item.receiver.phone }}
                    </div>
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
                <template #cell(user_sender)="data">
                  <div v-if="data.item.receiver" >
                    <div>
                      <span>User ID:</span> {{data.item.sender.uuid }}
                    </div>
                    <div v-if="data.item.sender.name">
                      <span>Họ tên:</span> {{data.item.sender.name }}
                    </div>
                    <div>
                      <span>SĐT:</span> {{data.item.sender.phone }}
                    </div>
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
                <template #cell(status)="data">
                  <div v-if="data.item.status == 0" >
                    <span class="label label-lg label-inline label-light-warning"> Chờ duyệt </span>
                  </div>
                  <div v-else>
                    <span class="label label-lg label-inline label-light-success"> Thành công </span>
                  </div>
                </template>
                <template #cell(created_at)="data">
                  {{formatDate(data.item.created_at)}}
                </template>
                <template #cell(action)="data">
                  <div v-if="data.item.status == 0" >
                    <button class="btn btn-sm label label-lg label-inline label-light-warning" @click="approve(data.item)"> Duyệt </button>
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.limit"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");

export default {
  name: "ReportCampaignStudent.vue",
  mixins: [Common],
  components:{
    DatePicker
  },
  data() {
    return {
      fields: [
        {
          key: "stt",
          label: "STT",
        },
        {
          key: "user_receiver",
          label: "Sinh viên",
        },
        {
          key: "user_sender",
          label: "Người giới thiệu",
        },
        {
          key: "status",
          label: "Trạng thái",
        },
        {
          key: "created_at",
          label: "Ngày tham gia",
        },
        {
          key: "action",
          label: "Hành động",
        },
      ],
      items: [],
      currentPage: 1,
      paramFilter:{
        phone:'',
        name:'',
        is_send_email:'',
        status_bonus:'',
        from_date:'',
        to_date:'',
      },
      arrayFilter:[],
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      clients: [],
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê refer sinh viên", route: "/campaign/report-student" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    getClassStatus(type, status) {
      if (type === 'refer') {
        if (status === 0) {
          return "label label-lg label-inline label-light-warning";
        } else {
          return "label label-lg label-inline label-light-success";
        }
      } else if (type === 'send-email') {
        if (status === 0) {
          return "label label-lg label-inline label-light-warning";
        } else if (status === 1) {
          return "label label-lg label-inline label-light-success";
        } else {
          return "label label-lg label-inline label-light-danger";
        }
      } else if (type === 'challenge') {
        if (status === 0) {
          return "label label-lg label-inline label-light-warning";
        } else if (status === 1) {
          return "label label-lg label-inline label-light-primary";
        } else if (status === 2) {
          return "label label-lg label-inline label-light-success";
        } else {
          return "label label-lg label-inline label-light-danger";
        }
      } else if (type === 'gift') {
        if (status === 0) {
          return "label label-lg label-inline label-light-warning";
        } else if (status === 1) {
          return "label label-lg label-inline label-light-success";
        } else {
          return "label label-lg label-inline label-light-danger";
        }
      }
    },
    formatDate(value){
      return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.getList();
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        this.paramFilter.page = this.currentPage;
        let response = await CampaignRepository.getListReportReferStudent(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let list =  body.data;
          this.items = list.data;
          this.arrayFilter = body.data.array_filter;
          this.paginate.total = list.meta.total;
          this.paginate.totalPage = list.meta.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async approve (item) {
      if (item.status !== 0) {
        return;
      }
      let params = {
        receiver_id: item.receiver_id,
      }
      let response = await CampaignRepository.approveReferStudent(params);
      if (!response) {
        this.$notify({
          group: "foo",
          type: "error",
          title: 'Có lỗi xảy ra.',
        });
      }else{
        this.$notify({
          group: "foo",
          type: "success",
          title: 'Phê duyệt thành công',
        });
        item.status = 1;
      }
    },
    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getClients();
    this.getList();
  }
};
</script>

<style >
.mx-datepicker{
  width: 100%;
}

</style>
